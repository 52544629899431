import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {
  "title": "Inisst",
  "date": "2020-05-24T00:00:00.000Z",
  "draft": true,
  "excerpt": "On more than one occasion, I've heard people conflate the cascade feature of CSS with inheritance."
};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h5></h5>
    <p>{`Have a good day.☕
`}<img parentName="p" {...{
        "src": "/f.gif",
        "alt": null
      }}></img></p>
    <hr></hr>
    <p><em parentName="p">{`Soon here will be more content...`}</em></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      